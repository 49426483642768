import React, { useState, useEffect } from "react";

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};
const headingStyles = {
  marginTop: 0,
  marginBottom: 24,
  maxWidth: 320,
};

const paragraphStyles = {
  marginBottom: 48,
};

// markup
const NotFoundPage = ({ location }) => {
  const [error, setError] = useState("");

  useEffect(() => {
    if (location && location.state && location.state.error) {
      const locationError = location.state.error;
      setError(locationError);
    }
  }, [location]);

  return (
    <main style={pageStyles}>
      <title>Not found</title>
      <h1 style={headingStyles}>404. Oh No!</h1>
      {error !== "" && (
        <h2>
          <strong>Error Type: </strong>
          {error}
        </h2>
      )}
      <p style={paragraphStyles}>
        Sorry{" "}
        <span role="img" aria-label="Pensive emoji">
          😔
        </span>{" "}
        we could not find the survey you were looking for or you do not have
        access.
        <br />
      </p>
    </main>
  );
};

export default NotFoundPage;
